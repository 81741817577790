import React, { useEffect, useState } from "react";
import TextBlock from "../UI/TextBlock";
import styles from "./ProfileContent.module.css";
import { useAuth } from "../Contexts/AuthContext";
import { useToast } from "../Contexts/ToastContext";
import UserCars from "../UI/UserCars";
import ProfileInformation from "./ProfileInformation";
import useFetchData from "../Hooks/useFetchData";
import RequestType from "../Models/RequestType";
import Loading from "../UI/Loading";

const ProfileView = ({ userId }) => {
  const { user } = useAuth();
  const { showToast } = useToast();
  const { fetchData: fetchProfileData } = useFetchData(
    userId
      ? `Profile/GetProfileUserData/${userId}`
      : `Profile/GetProfileUserData`
  );

  const { fetchData: fetchUpdateProfileData } = useFetchData(
    "Profile/UpdateUserProfile",
    RequestType.POST
  );

  const [profileUser, setProfileUser] = useState(null);
  const [profileText, setProfileText] = useState("");
  const [newProfileText, setNewProfileText] = useState("");
  const [isChangingPhoto, setIsChangingPhoto] = useState(false);
  const [isEditingProfile, setIsEditingProfile] = useState(false);
  const [newPhoto, setNewPhoto] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);

  const [isAnnouncementsStoryHidden, setIsAnnouncementsStoryHidden] =
    useState(false);
  const [isBidsStoryHidden, setIsBidsStoryHidden] = useState(false);

  const [activeTab, setActiveTab] = useState("bids");

  useEffect(() => {
    fetchProfileData()
      .then((data) => {
        setProfileUser(data);
        setProfileText(data.descriptionProfile || "");
        setNewPhoto(data.avatar || "/images/profile2.png");
        setIsAnnouncementsStoryHidden(data.isAnnouncementsStoryHidden);
        setIsBidsStoryHidden(data.isBidsStoryHidden);
        if (data.isBidsStoryHidden) {
          setActiveTab("announcements");
        }
      })
      .catch((err) => {
        showToast("Помилка", `Error fetching user data: ${err}`, "danger");
      });
  }, [userId, fetchProfileData, showToast]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleSaveProfile = async () => {
    setIsEditingProfile(false);
    if (isChangingPhoto) {
      await updateProfile();
    }
    setProfileText(newProfileText);
    setIsChangingPhoto(false);
  };

  const updateProfile = async () => {
    const formData = new FormData();
    formData.append("UserId", profileUser.userId);
    formData.append("UserName", profileUser.username);
    formData.append("Description", newProfileText);
    if (selectedFile) {
      formData.append("Avatar", selectedFile);
    }
    fetchUpdateProfileData(formData)
      .then((data) => {
        showToast("Успіх", data, "success");
        setSelectedFile(null);
      })
      .catch((err) => {
        showToast("Помилка", `Не вдалося оновити профіль: ${err}`, "danger");
      });
  };

  const isHideAnnouncements =
    user?.role?.includes("Moderator") || !isAnnouncementsStoryHidden;
  const isHideBids = user?.role?.includes("Moderator") || !isBidsStoryHidden;

  if (!profileUser) {
    return <Loading />;
  }

  return (
    <>
      <ProfileInformation
        user={user}
        profileUser={profileUser}
        newPhoto={newPhoto}
        setNewPhoto={setNewPhoto}
        isEditingProfile={isEditingProfile}
        setIsEditingProfile={setIsEditingProfile}
        profileText={profileText}
        newProfileText={newProfileText}
        setNewProfileText={setNewProfileText}
        setIsChangingPhoto={setIsChangingPhoto}
        setSelectedFile={setSelectedFile}
        handleSaveProfile={handleSaveProfile}
      />
      {userId &&
        (user.role.includes("Moderator") ||
          !isBidsStoryHidden ||
          !isAnnouncementsStoryHidden) && (
          <>
            <div className={styles.fullWidthLine}></div>
            <TextBlock
              color="#dbf6fa"
              className={`cardText ${styles.navProfile}`}
            >
              <ul className="d-flex justify-content-start flex-wrap">
                {isHideBids && (
                  <li>
                    <button
                      onClick={() => handleTabChange("bids")}
                      className={activeTab === "bids" ? styles.activeTab : ""}
                    >
                      <strong>Історія ставок</strong>
                    </button>
                  </li>
                )}
                {isHideAnnouncements && (
                  <li>
                    <button
                      onClick={() => handleTabChange("announcements")}
                      className={
                        activeTab === "announcements" ? styles.activeTab : ""
                      }
                    >
                      <strong>Історія оголошень</strong>
                    </button>
                  </li>
                )}
              </ul>
            </TextBlock>
            <div className="justify-content-between">
              {activeTab === "bids" && isHideBids && (
                <UserCars
                  urlController={`Profile/GetListBid?userId=`}
                  IdUser={userId}
                  title={`Cтавки ${profileUser.username} `}
                />
              )}
              {activeTab === "announcements" && isHideAnnouncements && (
                <UserCars
                  urlController={`Profile/GetListAnnouncement?userId=`}
                  IdUser={userId}
                  title={`Оголошень ${profileUser.username} `}
                />
              )}
            </div>
          </>
        )}
    </>
  );
};

export default ProfileView;
